import { PageWrapper } from 'components/PageWrapper/PageWrapper';
import { AnalystDirectoryElement } from 'features/analystDirectory/routes/AnalystDirectoryRoutes';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ResearchCompanyHome } from '../components/ResearchCompany/ResearchCompanyHome';
import { ResearchIndustryHome } from '../components/ResearchIndustry/ResearchIndustryHome';
import { RiskMonitorHome } from '../components/RiskMonitor/RiskMonitorHome';
import { ResultsContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { FiltersModalContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';

export function AccountingRoutes() {
    return (
        <Routes>
            <Route>
                <Route path='/*' element={<Navigate to='./risk-monitor' />} />
                <Route
                    path='/risk-monitor'
                    element={
                        <PageWrapper>
                            <ResultsContextProvider>
                                <FiltersModalContextProvider>
                                    <RiskMonitorHome />
                                </FiltersModalContextProvider>
                            </ResultsContextProvider>
                        </PageWrapper>
                    }
                />
                <Route path='/reports/company' element={<ResearchCompanyHome />} />
                <Route path='/reports/industry' element={<ResearchIndustryHome />} />
                <Route path='/analyst-directory' element={<AnalystDirectoryElement />} />
            </Route>
        </Routes>
    );
}
